/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.bg-cll-nav {
    // background-color: #fcfacc;
    @apply bg-primary-400;
}

.mdc-button {
    color: black !important;
}
.mat-mdc-dialog-component-host .text-secondary {
    text-align: justify !important;
}